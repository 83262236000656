import { Flex, type FlexProps, Spinner, Text } from '@chakra-ui/react'
import { defaultTo } from 'lodash'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type AmountLabelProps = {
  label: string
  amount: string | number
  asset: string
  textVariant?: string
  labelColor?: string
  isValueLoading?: boolean
} & FlexProps

export const AmountLabel = ({
  label,
  amount,
  asset,
  textVariant = 'text2regular',
  labelColor,
  isValueLoading = false,
  ...props
}: AmountLabelProps) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center" gap={4} {...props}>
      <Text
        variant={{ base: 'text3regular', md: textVariant }}
        color={defaultTo(labelColor, COLORS.grey02)}
      >
        {label}
      </Text>
      {isValueLoading ? (
        <Spinner size="sm" color={COLORS.grey02} ml="auto" />
      ) : (
        <Text
          wordBreak="break-all"
          ml="auto"
          variant={{ base: 'text3regular', md: textVariant }}
          color={COLORS.grey02}
        >
          {amount || 0} {asset}
        </Text>
      )}
    </Flex>
  )
}
