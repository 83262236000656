import {
  CrossChainMessenger,
  DEFAULT_L2_CONTRACT_ADDRESSES,
  type SignerOrProviderLike,
} from '@eth-optimism/sdk'
import { ethers } from 'ethers'
import { BRIDGE_MIN_GAS_LIMIT_ETH, CHAINS } from 'constants/network'
import { env } from 'env.client'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const depositETH = async ({
  amount,
  signer,
  setPendingTransaction,
}: {
  amount: string
  signer: SignerOrProviderLike
  setPendingTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
}) => {
  const { L1StandardBridge, L1StandardBridgeProxy, DEFAULT_L1_CONTRACT_ADDRESSES } =
    getSmartContracts()

  const crossChainMessenger = new CrossChainMessenger({
    l2ChainId: CHAINS.zircuit.id,
    l1ChainId: CHAINS.l1.id,
    l1SignerOrProvider: signer,
    l2SignerOrProvider: new ethers.providers.JsonRpcProvider(env.NEXT_PUBLIC_ZIRCUIT_WEB3_PROVIDER),
    bedrock: true,
    contracts: {
      l1: DEFAULT_L1_CONTRACT_ADDRESSES,
      l2: DEFAULT_L2_CONTRACT_ADDRESSES,
    },
  })

  const l1StandardBridgeContract = new ethers.Contract(
    L1StandardBridgeProxy.address,
    L1StandardBridge.abi,
  )

  const tx = await l1StandardBridgeContract
    .connect(crossChainMessenger.l1Signer)
    .populateTransaction.bridgeETH(
      BRIDGE_MIN_GAS_LIMIT_ETH,
      '0x', // extra data -> TODO: use uuid
      { value: ethers.utils.parseUnits(amount, 'ether') },
    )
  const gas = await crossChainMessenger.l1Provider.estimateGas(tx)
  // gas estimations are seemingly a bit too conservative, leading to failed
  // transactions. Increasing the gas limit by a bit should not
  // incur additional costs for the users since only the gas that is actually
  // used will have to be paid
  tx.gasLimit = gas.add(50_000)

  const response = await crossChainMessenger.l1Signer.sendTransaction(tx)

  setPendingTransaction(response)

  await response.wait()

  return response
}
