import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { BRIDGE_MIN_GAS_LIMIT_ERC20, CHAINS } from 'constants/network'
import { env } from 'env.client'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const withdrawERC20 = async ({
  amount,
  selectedAsset,
  signer,
  setPendingTransaction,
}: {
  amount: string
  selectedAsset: Erc20Token
  signer: ethers.providers.JsonRpcSigner
  setPendingTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
}) => {
  const { L1StandardBridge, L2StandardBridge, DEFAULT_L1_CONTRACT_ADDRESSES } = getSmartContracts()
  const signerAddress = await signer.getAddress()

  const crossChainMessenger = new CrossChainMessenger({
    l2ChainId: CHAINS.zircuit.id,
    l1ChainId: CHAINS.l1.id,
    l1SignerOrProvider: new ethers.providers.JsonRpcProvider(env.NEXT_PUBLIC_L1_NETWORK_RPC_URL),
    l2SignerOrProvider: signer,
    bedrock: true,
    contracts: {
      l1: DEFAULT_L1_CONTRACT_ADDRESSES,
      l2: DEFAULT_L2_CONTRACT_ADDRESSES,
    },
  })

  const bigIntAmount = ethers.utils.parseUnits(amount, selectedAsset.decimals_l2).toBigInt()

  // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
  // Check "getL2StandardBridge": https://github.com/zircuit/zkr-monorepo/blob/b3cca940f7aebe2ce578173ae0c339506f2f6613/packages/contracts-bedrock/qs-e2e-test/src/deployments.ts.
  const abi = JSON.parse(selectedAsset.abi_l1)

  // @ts-expect-error - Missing type
  // eslint-disable-next-line
  const isMintable = abi.find((method) => 'name' in method && method.name === 'mint')

  if (!isMintable) {
    await readContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'allowance',
      args: [signerAddress, L2StandardBridge.address],
    })

    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'approve',
      args: [L2StandardBridge.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  }

  const l2StandardBridgeContract = new ethers.Contract(
    L2StandardBridge.address,
    // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
    // Check "getL2StandardBridge": https://github.com/zircuit/zkr-monorepo/blob/b3cca940f7aebe2ce578173ae0c339506f2f6613/packages/contracts-bedrock/qs-e2e-test/src/deployments.ts.
    L1StandardBridge.abi,
  )

  const tx = await l2StandardBridgeContract
    .connect(crossChainMessenger.l2Signer)
    .populateTransaction.bridgeERC20(
      selectedAsset.contract_address_l2,
      selectedAsset.contract_address_l1,
      bigIntAmount,
      BRIDGE_MIN_GAS_LIMIT_ERC20,
      '0x',
    )

  const gas = await crossChainMessenger.l2Signer.estimateGas(tx)
  // gas estimations are seemingly a bit too conservative, leading to failed
  // transactions. Increasing the gas limit by a bit should not
  // incur additional costs for the users since only the gas that is actually
  // used will have to be paid
  tx.gasLimit = gas.add(200_000)

  const response = await crossChainMessenger.l2Signer.sendTransaction(tx)

  setPendingTransaction(response)

  await response.wait()

  return response
}
