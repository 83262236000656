import { Button, Flex, type FlexProps, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowLeftRightLine } from 'react-icons/ri'
import { Picker } from 'components/Picker'
import { ChainItem } from 'components/Picker/optionRenderer/ChainItem'
import { CHAINS } from 'constants/network'
import { useThemeColors } from 'hooks/useThemeColors'
import { getImageNameByChainId } from 'utils/getImageNameByChainId'

const chains = Object.values(CHAINS)

type Value = {
  id: number
  label: string
}

type BridgeSelectorProps = {
  from: Value
  onFromChange?: (props: Value) => void
  to: Value
  onToChange?: (props: Value) => void
  isDisabled?: boolean
} & FlexProps

export const BridgeSelector = ({
  from,
  onFromChange,
  to,
  onToChange,
  isDisabled,
  ...props
}: BridgeSelectorProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])

  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Picker
        flex={1}
        modalTitle={t('common:SelectNetwork')}
        label={t('common:From')}
        value={from}
        onValueChange={({ id, name }) => {
          onFromChange?.({ id, label: name })
        }}
        options={chains.filter((chain) => chain.id !== to.id)}
        imageSrc={`/assets/${getImageNameByChainId(from.id, colorMode)}.svg`}
        optionRenderer={ChainItem}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <Button
          mx={4}
          mt={6}
          variant="icon"
          bgColor={COLORS.bgPrimary}
          onClick={() => {
            onFromChange?.(to)
            onToChange?.(from)
          }}
        >
          <RiArrowLeftRightLine size={20} />
        </Button>
      )}
      <Picker
        flex={1}
        modalTitle={t('common:SelectNetwork')}
        label={t('common:To')}
        value={to}
        onValueChange={({ id, name }) => {
          onToChange?.({ id, label: name })
        }}
        options={chains.filter((chain) => chain.id !== from.id)}
        imageSrc={`/assets/${getImageNameByChainId(to.id, colorMode)}.svg`}
        optionRenderer={ChainItem}
        isDisabled={isDisabled}
      />
    </Flex>
  )
}
