import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, Image, Text, useColorMode, useMediaQuery, Spinner } from '@chakra-ui/react'
import { defaultTo } from 'lodash'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import { Modal } from 'components/Modal'
import { COLOR_MODE } from 'constants/common'
import { useThemeColors } from 'hooks/useThemeColors'
import { formatStringSvgToImageSrc } from 'utils/common'

type PickerProps<T> = {
  value: { id: string | number; label: string }
  onValueChange: (value: T) => void
  label?: string
  options: Array<T>
  imageSrc?: string
  svgImage?: string
  optionRenderer: (props: T) => React.ReactNode
  pickerStyle?: FlexProps
  iconColor?: string
  textVariant?: string
  modalTitle?: string
  isDisabled?: boolean
  isLoading?: boolean
} & FlexProps

export const Picker = <T extends Record<string, unknown> & { id: string | number }>({
  value,
  onValueChange,
  label,
  options,
  imageSrc,
  svgImage,
  optionRenderer,
  pickerStyle,
  iconColor,
  textVariant = 'title2medium',
  modalTitle,
  isDisabled,
  isLoading,
  ...props
}: PickerProps<T>) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const isLightMode = colorMode === COLOR_MODE.LIGHT
  const { t } = useTranslation(['common'])
  const [isOpen, setIsOpen] = React.useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const [isMobile] = useMediaQuery('(max-width: 500px)')

  return (
    <>
      <Box minW="fit-content" {...props}>
        {label && (
          <Text mb={1.5} variant="text3regular" color={COLORS.grey03}>
            {label}
          </Text>
        )}
        <Flex
          px={{ base: 2, md: 4 }}
          py={{ base: 2, md: 4 }}
          alignItems="center"
          justifyContent="space-between"
          bgColor={COLORS.bgPrimary}
          border="1px solid"
          borderColor={COLORS.grey06}
          borderRadius={8}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          {...(!isDisabled && {
            _hover: { borderColor: isLightMode ? 'beige03' : 'darkGrey03' },
            onClick: handleOpen,
          })}
          {...pickerStyle}
        >
          <Flex gap={{ base: 1, md: 2.5 }} alignItems="center">
            {imageSrc && <Image src={imageSrc} {...(isMobile && { boxSize: 5 })} />}
            {svgImage && (
              <Image src={formatStringSvgToImageSrc(svgImage)} {...(isMobile && { boxSize: 5 })} />
            )}
            <Text variant={{ base: 'title2medium', md: textVariant }}>
              {isMobile ? value.label.substring(0, 3).toUpperCase() : value.label}
            </Text>
          </Flex>
          <Box mt={0.5} ml={{ base: 2, md: 6 }}>
            <RiArrowDownSLine color={defaultTo(iconColor, COLORS.dark01)} size={20} />
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} title={modalTitle ?? t('common:Select')} onClose={handleClose}>
        {isLoading ? (
          <Flex justifyContent="center">
            <Spinner size="sm" color={COLORS.grey02} />
          </Flex>
        ) : (
          options.map((option) => (
            <Box
              key={option.id}
              p={2.5}
              mb={2}
              border="1px solid"
              borderRadius={8}
              borderColor={COLORS.white}
              {...(value.id === option.id && {
                borderColor: isLightMode ? 'zircuitLight' : 'darkGrey06',
              })}
              _hover={{ borderColor: isLightMode ? 'grey04' : 'zircuitLight' }}
              cursor="pointer"
              onClick={() => {
                onValueChange(option)
                handleClose()
              }}
            >
              {/* Here is a custom renderer for flexibility with generic (T) props. See src/component/Picker/optionRenderer. */}
              {optionRenderer(option)}
            </Box>
          ))
        )}
      </Modal>
    </>
  )
}
