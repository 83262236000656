import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { ethers } from 'ethers'
import { useAccount, useBalance } from 'wagmi'
import { DEFAULT_VALUE } from 'constants/common'
import { chainIdProviderMap, L2_LAYER_IDS } from 'constants/network'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

type UseGetGasFeeProps = {
  amountToTransfer: string
  chainId: number
}

export const useGetGasFee = ({ amountToTransfer, chainId }: UseGetGasFeeProps) => {
  const { address: addressFrom, isConnected } = useAccount()
  const { data } = useBalance()

  const balance = data?.formatted ?? DEFAULT_VALUE

  return useQuery({
    enabled: isConnected,
    queryKey: ['getGasFee', amountToTransfer, chainId],
    queryFn: async () => {
      const { L1StandardBridgeProxy, L2StandardBridge } = getSmartContracts()

      const addressTo = L2_LAYER_IDS.includes(chainId)
        ? L2StandardBridge.address
        : L1StandardBridgeProxy.address

      const provider = new ethers.providers.JsonRpcProvider(chainIdProviderMap[chainId])

      // https://www.0xdev.co/how-to-estimate-the-gas-fees-to-pay-for-a-transaction-using-ethers-js-and-javascript/
      const gasAmount = await provider.estimateGas({
        from: addressFrom,
        to: addressTo,
        value: ethers.utils.parseEther(
          Number(balance) > Number(amountToTransfer) ? amountToTransfer : DEFAULT_VALUE,
        ),
      })

      const gasPrice = await provider.getGasPrice()

      const gasFeeInWei = gasPrice.mul(gasAmount)
      const gasFeeInETH = ethers.utils.formatEther(gasFeeInWei)

      return gasFeeInETH
    },
    gcTime: 0,
    /**
     * Gas estimation sometimes fails on the following error:
     * ResourceMetering: too many deposits in this block
     * to fix this we need to retry the query infinitely, until it succeeds
     */
    retry: true,
  }) as UseQueryResult<string>
}
