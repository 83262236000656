import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { WEB3_RESPONSE_ERROR_PREFIX } from 'constants/common'
import { ETH, L1_LAYER_IDS } from 'constants/network'
import { useToast } from 'hooks/useToast'
import { useAssetContext } from 'providers/AssetProvider'
import type { ExternalProvider } from 'types/network'
import { depositETH, withdrawETH, depositERC20, withdrawERC20 } from 'utils/bridge'

type UseBridgeProps = {
  amount: string
  fromId: number
  setIsSwitchNetworkModalOpen: (value: boolean) => void
  setPendingTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
  setBridgedTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
  isPendingTransactionQuarantined: boolean
}

export const useBridge = ({
  amount,
  fromId,
  setIsSwitchNetworkModalOpen,
  setPendingTransaction,
  setBridgedTransaction,
  isPendingTransactionQuarantined,
}: UseBridgeProps) => {
  const { connector, chain } = useAccount()
  const toast = useToast()
  const { selectedAsset, refetchAssetsWithBalance } = useAssetContext()

  // https://ethereum.org/ml/developers/tutorials/optimism-std-bridge-annotated-code/.
  const handleBridge = async () => {
    if (chain!.id !== fromId) {
      setIsSwitchNetworkModalOpen(true)

      return
    }

    // Connector is defined here - to proceed to this step the wallet must be connected.
    const provider = (await connector!.getProvider()) as ExternalProvider
    const signer = new ethers.providers.Web3Provider(provider).getSigner()

    try {
      let tx: ethers.providers.TransactionResponse | null = null

      if (selectedAsset.symbol === ETH.symbol) {
        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositETH({ amount, signer, setPendingTransaction })
          : withdrawETH({ amount, signer, setPendingTransaction }))
      } else {
        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositERC20({
              amount,
              selectedAsset: selectedAsset as Erc20Token,
              signer,
              setPendingTransaction,
            })
          : withdrawERC20({
              amount,
              selectedAsset: selectedAsset as Erc20Token,
              signer,
              setPendingTransaction,
            }))
      }

      if (!isPendingTransactionQuarantined) {
        setBridgedTransaction(tx)
      }

      await refetchAssetsWithBalance()
    } catch (error) {
      if (error instanceof Error) {
        if ('reason' in error && typeof error.reason === 'string') {
          toast({
            status: 'danger',
            message: error.reason.replace(WEB3_RESPONSE_ERROR_PREFIX, ''),
          })
        } else {
          toast({ status: 'danger', message: error.message })
        }
      }
    }
  }

  return { handleBridge }
}
