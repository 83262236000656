import type { ColorMode } from '@chakra-ui/react'
import { CHAINS } from 'constants/network'

export const getImageNameByChainId = (id: number, theme: ColorMode) => {
  switch (id) {
    case CHAINS.zircuit.id:
      return `zircuit_${theme}`
    case CHAINS.l1.id:
      return 'l1'
    default:
      return null
  }
}
