import {
  CrossChainMessenger,
  DEFAULT_L2_CONTRACT_ADDRESSES,
  type SignerOrProviderLike,
} from '@eth-optimism/sdk'
import { ethers } from 'ethers'
import { BRIDGE_MIN_GAS_LIMIT_ETH, CHAINS } from 'constants/network'
import { env } from 'env.client'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const withdrawETH = async ({
  amount,
  signer,
  setPendingTransaction,
}: {
  amount: string
  signer: SignerOrProviderLike
  setPendingTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
}) => {
  const { L1StandardBridge, L2StandardBridge, DEFAULT_L1_CONTRACT_ADDRESSES } = getSmartContracts()

  const crossChainMessenger = new CrossChainMessenger({
    l2ChainId: CHAINS.zircuit.id,
    l1ChainId: CHAINS.l1.id,
    l1SignerOrProvider: new ethers.providers.JsonRpcProvider(env.NEXT_PUBLIC_L1_NETWORK_RPC_URL),
    l2SignerOrProvider: signer,
    bedrock: true,
    contracts: {
      l1: DEFAULT_L1_CONTRACT_ADDRESSES,
      l2: DEFAULT_L2_CONTRACT_ADDRESSES,
    },
  })

  const l2StandardBridgeContract = new ethers.Contract(
    L2StandardBridge.address,
    // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
    // Check "getL2StandardBridge": https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/deployments.ts
    L1StandardBridge.abi,
  )

  const response = (await l2StandardBridgeContract.connect(crossChainMessenger.l2Signer).bridgeETH(
    BRIDGE_MIN_GAS_LIMIT_ETH,
    '0x', // extra data -> TODO: use uuid
    { value: ethers.utils.parseUnits(amount, 'ether') },
  )) as ethers.providers.TransactionResponse

  setPendingTransaction(response)

  await response.wait()

  return response
}
