import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { BRIDGE_MIN_GAS_LIMIT_ERC20, CHAINS } from 'constants/network'
import { env } from 'env.client'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const depositERC20 = async ({
  amount,
  selectedAsset,
  signer,
  setPendingTransaction,
}: {
  amount: string
  selectedAsset: Erc20Token
  signer: ethers.providers.JsonRpcSigner
  setPendingTransaction: React.Dispatch<
    React.SetStateAction<ethers.providers.TransactionResponse | null>
  >
}) => {
  const { L1StandardBridge, L1StandardBridgeProxy, DEFAULT_L1_CONTRACT_ADDRESSES } =
    getSmartContracts()
  const signerAddress = await signer.getAddress()

  const crossChainMessenger = new CrossChainMessenger({
    l2ChainId: CHAINS.zircuit.id,
    l1ChainId: CHAINS.l1.id,
    l1SignerOrProvider: signer,
    l2SignerOrProvider: new ethers.providers.JsonRpcProvider(env.NEXT_PUBLIC_ZIRCUIT_WEB3_PROVIDER),
    bedrock: true,
    contracts: {
      l1: DEFAULT_L1_CONTRACT_ADDRESSES,
      l2: DEFAULT_L2_CONTRACT_ADDRESSES,
    },
  })

  const bigIntAmount = ethers.utils.parseUnits(amount, selectedAsset.decimals_l1).toBigInt()

  const abi = JSON.parse(selectedAsset.abi_l1)

  // @ts-expect-error - Missing type
  // eslint-disable-next-line
  const isMintable = abi.find((method) => 'name' in method && method.name === 'mint')

  if (isMintable) {
    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'approve',
      args: [L1StandardBridgeProxy.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  } else {
    await readContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'allowance',
      args: [signerAddress, L1StandardBridgeProxy.address],
    })

    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'approve',
      args: [L1StandardBridgeProxy.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  }

  const l1StandardBridgeContract = new ethers.Contract(
    L1StandardBridgeProxy.address,
    L1StandardBridge.abi,
  )

  const tx = await l1StandardBridgeContract
    .connect(crossChainMessenger.l1Signer)
    .populateTransaction.bridgeERC20(
      selectedAsset.contract_address_l1,
      selectedAsset.contract_address_l2,
      bigIntAmount,
      BRIDGE_MIN_GAS_LIMIT_ERC20,
      '0x',
    )

  const gas = await crossChainMessenger.l1Signer.estimateGas(tx)

  // gas estimations are seemingly a bit too conservative, leading to failed
  // transactions. Increasing the gas limit by a bit should not
  // incur additional costs for the users since only the gas that is actually
  // used will have to be paid
  tx.gasLimit = gas.add(100_000)

  const response = await crossChainMessenger.l1Signer.sendTransaction(tx)

  setPendingTransaction(response)

  await response.wait()

  return response
}
