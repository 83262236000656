import { Box, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import type { Chain } from 'viem'
import { useThemeColors } from 'hooks/useThemeColors'
import { getImageNameByChainId } from 'utils/getImageNameByChainId'

export const ChainItem = ({ id, name, nativeCurrency }: Chain) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()

  return (
    <Flex alignItems="center">
      <Image src={`/assets/${getImageNameByChainId(id, colorMode)}.svg`} />
      <Box ml={3}>
        <Text variant="title2medium">{name}</Text>
        <Text color={COLORS.grey02} variant="caption2medium">
          {nativeCurrency.symbol}
        </Text>
      </Box>
    </Flex>
  )
}
