import type { LayoutProps, CardProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from '@chakra-ui/react'
import React from 'react'
import { RiInformationLine } from 'react-icons/ri'
import PulseLoader from 'react-spinners/PulseLoader'
import { Pagination } from 'components/Pagination'
import { COLOR_MODE, PAGE_LENGTH } from 'constants/common'
import { useThemeColors } from 'hooks/useThemeColors'

type TableProps<DataEntry> = {
  totalCount?: number
  currentPage?: number
  onPageChange?: (newPage: number) => Promise<void>
  title?: string
  data: DataEntry[]
  renderTableHeader?: () => React.ReactElement
  renderTableRow?: (row: DataEntry) => React.ReactElement
  renderMobile?: (row: DataEntry) => React.ReactElement
  leftHeaderContent?: React.ReactElement
  rightHeaderContent?: React.ReactElement
  hasPagination?: boolean
  hasTopContent?: boolean
  isLoading: boolean
  variant?: string
  loaderHeight?: LayoutProps['height']
} & CardProps

const Loader = ({ isLoading, height }: { isLoading: boolean; height: LayoutProps['height'] }) => {
  const COLORS = useThemeColors()

  return (
    <Flex h={height} justifyContent="center" alignItems="center" gap={1.5}>
      {isLoading ? (
        <PulseLoader size={4} color={COLORS.dark01} />
      ) : (
        <Icon as={RiInformationLine} boxSize={3.5} />
      )}
      <Text variant="text2regular">
        {isLoading ? 'Loading...' : 'There are no matching entries'}
      </Text>
    </Flex>
  )
}

export const Table = <T,>({
  data,
  totalCount,
  currentPage,
  onPageChange,
  title,
  renderTableRow,
  renderTableHeader,
  renderMobile,
  leftHeaderContent,
  rightHeaderContent,
  hasPagination = true,
  hasTopContent = true,
  isLoading,
  variant = 'primary',
  loaderHeight = '70vh',
  ...props
}: TableProps<T>) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const isEmpty = data.length === 0

  return (
    <Box h="100%" {...props}>
      {hasTopContent && (
        <Flex gap={5} mb={5} alignItems="center" flexWrap="wrap">
          <Flex alignItems="center" gap={{ base: 1, md: 3 }}>
            {title && (
              <Text
                {...(renderTableHeader
                  ? { variant: { base: 'title2medium', md: 'heading4medium' } }
                  : { color: COLORS.grey02 })}
                textTransform="capitalize"
              >
                {title}
              </Text>
            )}
            {leftHeaderContent && <Flex>{leftHeaderContent}</Flex>}
          </Flex>
          {!isLoading &&
            !isEmpty &&
            hasPagination &&
            !!totalCount &&
            !!currentPage &&
            !!onPageChange && (
              <Pagination
                ml="auto"
                count={Math.ceil(totalCount / PAGE_LENGTH)}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
          {rightHeaderContent && <Box ml="auto">{rightHeaderContent}</Box>}
        </Flex>
      )}
      {renderTableRow && (
        <TableContainer
          bgColor={colorMode === COLOR_MODE.LIGHT ? 'grey07' : 'grey09'}
          borderRadius={10}
        >
          <ChakraTable variant={variant}>
            {renderTableHeader && (
              <Thead>
                <Tr>{renderTableHeader()}</Tr>
              </Thead>
            )}
            {isLoading || isEmpty ? (
              <TableCaption py={0}>
                <Loader isLoading={isLoading} height={loaderHeight} />
              </TableCaption>
            ) : (
              <Tbody>{data.map(renderTableRow)}</Tbody>
            )}
          </ChakraTable>
        </TableContainer>
      )}
      {renderMobile && (isLoading || isEmpty) ? (
        <Loader isLoading height={loaderHeight} />
      ) : (
        renderMobile && data.map(renderMobile)
      )}
    </Box>
  )
}

Table.HeaderCell = Th
Table.Row = Tr
Table.RowCell = Td
