import type { ethers } from 'ethers'
import type React from 'react'
import { useEffect } from 'react'
import type { IsTransactionQuarantinedQuarantine } from 'generated/reactQueryClient'
import { useGetQuarantinedTransactionByTxHash } from 'generated/reactQueryClient'

type UseHandleQuarantinedTransactionProps = {
  pendingTransaction: Partial<ethers.providers.TransactionResponse> | null
  setIsBridgeApproveModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsBridgeQuarantinedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type UseHandleQuarantinedTransactionReturn = {
  currentQuarantinedTransactionData?: IsTransactionQuarantinedQuarantine
  isPendingTransactionQuarantined: boolean
}

export const useHandleQuarantinedTransaction = ({
  pendingTransaction,
  setIsBridgeApproveModalOpen,
  setIsBridgeQuarantinedModalOpen,
}: UseHandleQuarantinedTransactionProps): UseHandleQuarantinedTransactionReturn => {
  const { data: { isQuarantined, quarantine } = {}, isLoading } =
    useGetQuarantinedTransactionByTxHash(
      pendingTransaction?.hash as string, // *: At this point, the hash should be always present due to the enabled param below
      undefined,
      { query: { enabled: Boolean(pendingTransaction?.hash?.length) } },
    )

  useEffect(() => {
    if (!isLoading && Boolean(quarantine)) {
      setIsBridgeApproveModalOpen(false)
      setIsBridgeQuarantinedModalOpen(true)
    }
  }, [isLoading, quarantine, setIsBridgeApproveModalOpen, setIsBridgeQuarantinedModalOpen])

  return {
    currentQuarantinedTransactionData: quarantine,
    isPendingTransactionQuarantined: Boolean(isQuarantined),
  }
}
